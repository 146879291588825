import { type ProgramType } from 'Program';

export enum DocumentType {
    enrollment_agreement = 'enrollment_agreement',
    tila_disclosure = 'tila_disclosure',
}

export type SignableDocument = {
    id: string;
    documentType: DocumentType;
    signedAt: number | undefined;
    signingLink: string;
    metadata: {
        programType: ProgramType;
    };
};
