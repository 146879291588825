import { type SignableDocument } from 'SignableDocument/SignableDocument.types';

export function isSigned(signableDocument: SignableDocument | null) {
    return !!signableDocument?.signedAt;
}

export function signingLink(signableDocument: SignableDocument | null) {
    if (isSigned(signableDocument)) return null;
    return signableDocument?.signingLink ?? null;
}

export function getDownloadUrl(id: SignableDocument['id']) {
    return `${window.ENDPOINT_ROOT}/api/signable_documents/show_file/${id}`;
}
