export enum StripeProductId {
    'emba' = 'emba',
    'mba' = 'mba',
    'emba_strategic_leadership' = 'emba_strategic_leadership',
    'mba_leadership' = 'mba_leadership',
    'msba' = 'msba',
    'msse' = 'msse',
    'cmo_cert' = 'cmo_cert',
    'executive_cert' = 'executive_cert',
    'founders_cert' = 'founders_cert',
    'cto_cert' = 'cto_cert',
    'data_science_foundations_cert' = 'data_science_foundations_cert',
    'bus_analytics_leaders_cert' = 'bus_analytics_leaders_cert',
    'fin_for_non_fin_managers_cert' = 'fin_for_non_fin_managers_cert',
    'ai_for_technical_leaders_cert' = 'ai_for_technical_leaders_cert',
    'prototype_gpt_cert' = 'prototype_gpt_cert',
    'tyb_blockchain_cert' = 'tyb_blockchain_cert',
    'tyb_ai_cert' = 'tyb_ai_cert',
}

export type StripeProduct = {
    id: StripeProductId;
};
